<template>
  <div v-if="keyValue">
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-img
          v-if="optionsLocal.image"
          ref="previewEl"
          :src="optionsLocal.image"
          rounded
          height="80"
        />
        <b-img
          v-else
          ref="previewEl"
          rounded
          :src="avatar"
          width="125"
        />
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="optionsLocal.File"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="onFileChange"
        />
        <!--/ upload button -->

        <!-- reset -->
        <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button> -->
        <!--/ reset -->
        <b-card-text>Foto JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nama Proyek"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.nama"
              placeholder="Nama Proyek"
              name="nama"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Target"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.target"
              name="name"
              placeholder="Target"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Anggaran"
            label-for="account-name"
          >
            <cleave
              v-model="optionsLocal.anggaran"
              class="mb-2 form-control"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Status"
            label-for="account-name"
          >
            <v-select
              id="statusList"
              v-model="optionsLocal.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              item-text="id"
              item-value="label"
            />
          </b-form-group>
        </b-col>
        <!-- birth date -->
        <b-col md="6">
          <b-form-group
            label-for="example-datepicker"
            label="Tanggal Mulai"
          >
            <flat-pickr
              v-model="optionsLocal.tanggal_mulai"
              class="form-control"
              name="date"
              placeholder="Tanggal Mulai"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="example-datepicker"
            label="Tanggal Akhir"
          >
            <flat-pickr
              v-model="optionsLocal.tanggal_akhir"
              class="form-control"
              name="date"
              placeholder="Tanggal Akhir"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Deskripsi"
            label-for="deskripsi-area"
          >
            <b-form-textarea
              id="deskripsi-area"
              v-model="optionsLocal.deskripsi"
              rows="4"
              placeholder="Deskripsi Proyek disini..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Outcome"
            label-for="outcome-area"
          >
            <b-form-textarea
              id="outcome-area"
              v-model="optionsLocal.outcome"
              rows="4"
              placeholder="Outcome Proyek disini..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> //-->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
  </div>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormTextarea, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import router from '@/router'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  getProyek,
} from '@/api/proyek'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    flatPickr,
    BFormTextarea,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      keyValue: true,
      statusOption: [
        {
          id: 1,
          label: 'Aktif',
        },
        {
          id: 2,
          label: 'Tidak Aktif',
        },
      ],
      optionsLocal: {
        status: {
          id: 1,
          label: 'Aktif',
        },
      },
      localData: [],
      profileFile: null,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      /* eslint-disable global-require */
      avatar: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.keyValue = false
      const response = await getProyek(this.$route.params.id)
      this.localData = response.data.data
      // console.log(this.localData)
      this.optionsLocal.image = this.localData.image
      this.optionsLocal.nama = this.localData.nama
      this.optionsLocal.target = this.localData.target
      this.optionsLocal.anggaran = this.localData.anggaran
      // this.optionsLocal.status.id = this.localData.status.id
      // this.optionsLocal.status.label = this.localData.status.label
      this.optionsLocal.tanggal_mulai = this.localData.tanggal_mulai
      this.optionsLocal.tanggal_akhir = this.localData.tanggal_akhir
      this.optionsLocal.deskripsi = this.localData.deskripsi
      this.optionsLocal.outcome = this.localData.outcome
      // this.optionsLocal.img = `${process.env.VUE_APP_API_URL}/proyek/download/${this.localData.img}`
      this.keyValue = true
    }
  },
  methods: {
    resetForm() {
      // this.optionsLocal = []
    },
    simpan() {
      this.localData = this.optionsLocal
      this.localData.status_id = this.optionsLocal.status.id
      this.$emit('clicked', this.localData)
    },
    onFileChange(e) {
      const file = e// e.target.files[0]
      this.optionsLocal.image = URL.createObjectURL(file)
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      refInputEl,
      previewEl,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
