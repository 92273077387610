<template>
  <b-overlay
    :show="busy"
    rounded="sm"
    v-if="$can('import-proyek', 'manage')"
  >
    <b-overlay
      :show="show"
      no-wrap
    />

  <b-card-code title="Import User Proyek">
    <b-row>
        <b-col xl="12" class="mb-1">
          <div class="d-flex">
            <b-media-body class="mt-75 ml-75">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75 mr-75"
                @click="downloadTemplate()"
              >
                Download Template Excel
              </b-button>
            </b-media-body>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12">
          <div class="d-flex">
            <b-media-body class="mt-75 ml-75">
              <b-form-input
                v-model="localData.filename"
                placeholder="Upload File !"
                :disabled="true"
              />
              <label style="color: red;"><i>Format that allowed only .xlsx</i></label>
            </b-media-body>
            <b-media-body class="mt-75 ml-75">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75 mr-75"
                @click="$refs.refInputElFoto.$el.click()"
                :disabled="buttonPilih === false"
              >
                Pilih File
              </b-button>
              <b-button
                v-if="localData.filename !== 'Upload File!'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75 mr-75"
                @click="upload()"
                :disabled="buttonPilih === false"
              >
                Submit
              </b-button>
              <b-form-file
                ref="refInputElFoto"
                v-model="localData.File"
                accept=".xlsx"
                :hidden="true"
                plain
                @input="onFileChange(localData.File)"
              />
            </b-media-body>
          </div>
        </b-col>
        <b-col xl="12">
          <div class="d-flex mt-2 ml-1">
             <label style="color: black;" class="mr-2">Success : {{tempData.success}}</label>
             <label style="color: black;" class="mr-2">Warning : {{tempData.warning}}</label>
             <label style="color: black;" class="mr-2">Failed : {{tempData.failed}}</label>
             <label style="color: black;" class="mr-2">New User : {{tempData.new}}</label>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
    <div
      class="d-flex justify-content-center mb-1"
      v-if="loaderShow === true"
    >
      <b-card>
        <b-card-text>
          <strong>Sedang menunggu proses import data</strong>
        </b-card-text>
        <b-spinner
          v-for="(variant, i) in variants"
          :key="i"
          :variant="variant"
          class="mr-1"
          type="grow"
        />
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// BFormGroup
// BFormInput
// BBadge
import {
  BAvatar, BPagination, BFormSelect, BDropdown, BDropdownItem, BRow, BCol, BButton, BModal, BToast, BOverlay, BCardText, BFormFile, BMediaBody, BFormInput, BCard, BSpinner,
} from 'bootstrap-vue'
import {
  getUserProyek, getListUserProyek, insUserProyek, delUserProyek, uploadFileExcel, exportExcel,
} from '@/api/proyek'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BCardCode,
    // VueGoodTable,
    // BAvatar,
    // BPagination,
    // BFormSelect,
    // BDropdown,
    // BDropdownItem,
    BRow,
    BCol,
    BButton,
    BCard,
    // BModal,
    // BToast,
    BOverlay,
    BCardText,
    BFormFile,
    BMediaBody,
    BFormInput,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      incKey: 0,
      filename: null,
      tempData: {},
      loaderShow: false,
      buttonPilih: true,
      localData: {
        filename: 'Upload File!',
      },
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'primary'],
      busy: false,
      show: false,
      koordinator_id: null,
      koordinator_jenis: null,
      processing: null,
      indexMainUser: null,
      rowMainUser: {},
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'User',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Nama',
          },
        },
        // {
        //   label: 'Jenis',
        //   field: 'jenis_user',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Cari Jenis',
        //   },
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      rowsTotal: 0,
      searchTerm: '',
      pageLengthSub: 10,
      columnsSub: [
        {
          label: 'User',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Nama',
          },
        },
        // {
        //   label: 'Jenis',
        //   field: 'jenis',
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rowsSub: [],
      rowsSubTotal: 0,
      searchTermSub: '',
      optionsLocal: {
        select_jabatan: {
          selected: null,
          data: [
            { id: 4, nama: 'Ketua Proyek' },
            { id: 5, nama: 'Admin Proyek' },
          ],
        },
      },
      dataTempUser: [],
      /* eslint-disable global-require */
      avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
      serverParamsMain: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Aktif : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async mounted() {
    try {
      this.loadRowsSub()
      this.loadRows()
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    async downloadTemplate() {
      const result = await exportExcel()
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', `TemplateUserProyek-${date}.xlsx`)
      document.body.appendChild(link)
      link.click()
    },
    async upload() {
      this.buttonPilih = false
      this.loaderShow = true
      const responseUpload = await uploadFileExcel(this.localData.File, 'user-proyek', event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      }, this.$route.params.id)
      if (responseUpload.success === false) {
        this.buttonPilih = true
        this.loaderShow = false
        this.makeToast('danger', responseUpload.data.message)
      } else {
        this.buttonPilih = true
        this.loaderShow = false
        this.makeToast('success', responseUpload.data.message)
        this.$emit('emitFilename', this.localData.filename)
        this.tempData = responseUpload.data.data
      }
    },
    onFileChange(val) {
      this.localData.filename = val.name
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadRowsSub()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadRowsSub()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadRowsSub()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadRowsSub()
    },
    updateParamsMain(newProps) {
      this.serverParamsMain = { ...this.serverParamsMain, ...newProps }
    },
    onPageChangeMain(params) {
      this.updateParamsMain({ page: params.currentPage })
      this.loadRows()
    },
    onPerPageChangeMain(params) {
      this.updateParamsMain({ perPage: params.currentPerPage })
      this.loadRows()
    },
    onSortChangeMain(params) {
      this.updateParamsMain({
        sort: params,
      })
      this.loadRows()
    },
    onColumnFilterMain(params) {
      this.updateParamsMain(params)
      this.loadRows()
    },
    async loadRows() {
      const responseListUserProyek = await getListUserProyek(this.$route.params.id, 'proyek', this.serverParamsMain)
      this.rows = responseListUserProyek.data.data
      this.rowsTotal = responseListUserProyek.data.total
    },
    async loadRowsSub() {
      const response = await getUserProyek(this.$route.params.id, 'proyek', this.serverParams)
      this.rowsSub = response.data.data
      this.rowsSubTotal = response.data.total
    },
    async tambah(index, userId, jabatan) {
      jabatan = 5
      if (jabatan) {
        const formData = new FormData()
        formData.append('user_id', userId)
        formData.append('proyek_id', this.$route.params.id)
        formData.append('jenis_user_id', jabatan)

        this.optionsLocal.select_jabatan.data.forEach(element => {
          if (element.id === jabatan) {
            this.koordinator_jenis = element.nama
          }
        })

        try {
          const response = await insUserProyek(formData)
          if (response.data.status.code === '00') {
            this.makeToast('success', 'Tambah Admin Proyek Berhasil!')
          } else {
            this.makeToast('danger', response.data.message)
          }
          this.loadRows()
          this.loadRowsSub()
        } catch (error) {
          this.makeToast('danger', JSON.stringify(error.response.data))
        }
      } else {
        this.makeToast('danger', 'Pilih Jabatan!')
      }
    },
    deleteKoordinator(index, userid, row) {
      this.busy = true
      this.koordinator_id = userid
      this.indexMainUser = index

      this.rowMainUser.created_at = row.created_at
      this.rowMainUser.email = row.email
      this.rowMainUser.email_verified_at = row.email_verified_at
      this.rowMainUser.updated_at = row.updated_at
      this.rowMainUser.id = row.id
      this.rowMainUser.jenis = null
      this.rowMainUser.name = row.name
      this.rowMainUser.username = row.username
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      try {
        this.busy = false
        this.show = true
        await delUserProyek(this.$route.params.id, this.koordinator_id, 0)
        this.show = false
        // this.rowsSub.push(this.rowMainUser)
        this.rows.splice(this.indexMainUser, 1)
        this.makeToast('success', 'Hapus Admin Proyek Berhasil!')
        this.loadRowsSub()
      } catch (error) {
        this.makeToast('danger', JSON.stringify(error.response.data))
        this.show = false
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Proyek',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
