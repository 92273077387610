<template>
  <b-tabs
    horizontal
    content-class="col-12 col-md-12 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab
      :active="activeProyek"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Proyek</span>
      </template>

      <form-proyek
        @clicked="simpanProyek"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab
      :active="activeUser"
      v-if="this.$route.params.id && $can('tabadmin', 'proyek')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Admin &amp; Mitra</span>
      </template>

      <form-admin
        :information-data="options.info"
      />
    </b-tab>
    <!-- social links -->
    <b-tab v-if="this.$route.params.id && $can('tabuser', 'proyek')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">User</span>
      </template>

      <form-user
        :social-data="options.social"
      />
    </b-tab>

    <b-tab
      :active="activeUser"
      v-if="this.$route.params.id && $can('import-proyek', 'manage')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Import User Proyek</span>
      </template>

      <form-import
      @loadNamaFile="loadNamaFile"
      />
      <!-- <b-row v-if="filename !== null && $can('log-import', 'manage')">
        <b-col xl="3" class="mb-1">
          <div class="d-flex justify-content-end">
            <b-media-body class="mt-75 ml-75">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="mb-75 mr-75"
                @click="saveToDatabase()"
                :disabled="loaderShow === true"
              >
                Save to Database
              </b-button>
            </b-media-body>
          </div>
        </b-col>
         <b-col xl="9" class="mb-1">
         <div
          class="d-flex justify-content-center mb-1"
          v-if="loaderShow === true"
          >
              <b-spinner
                v-for="(variant, i) in variants"
                :key="i"
                :variant="variant"
                class="mr-1"
                type="grow"
              />
          </div>
        </b-col>
      </b-row> -->
      <!-- <log-import
      :hideNamaFile="true"
      :filename="filename"
      v-if="filename !== null"
      /> -->
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BMediaBody, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import {
  insProyek,
  ubahProyek,
  uploadFileProyek,
  importFromLog,
} from '@/api/proyek'
import FormImport from '@/views/log-import/list/List.vue'
import FormProyek from './form/FormProyek.vue'
import FormAdmin from './form/FormAdmin.vue'
import FormUser from './form/FormUser.vue'

export default {
  components: {
    BTabs,
    BTab,
    FormProyek,
    FormAdmin,
    FormUser,
    FormImport,
    // LogImport,
    // BButton,
    // BMediaBody,
    // BRow,
    // BCol,
    // BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaderShow: false,
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'primary'],
      hitKey: 1,
      options: {},
      activeProyek: true,
      activeUser: false,
      filename: null,
    }
  },
  async mounted() {
    if (this.$router.currentRoute.query.userId) {
      this.activeProyek = false
      this.activeUser = true
    }
  },
  methods: {
    async saveToDatabase() {
      if (window.confirm(`Apakah anda yakin untuk menyimpan data ${this.filename} ke database?`)) {
        this.loaderShow = true
        const data = {
          filename: this.filename,
        }
        const response = await importFromLog(data)
        if (response.data.success === true) {
          this.makeToast('success', 'Data berhasil disimpan!')
          this.loaderShow = false
          setTimeout(() => this.$router.go('/'), 2000)
          this.$router.go('/')
        } else {
          this.makeToast('danger', response.data.message)
          this.loaderShow = false
        }
      }
    },
    loadNamaFile(value) {
      this.filename = value
      this.hitKey += 1
    },
    async simpanProyek(value) {
      let imagefilename = ''
      if (value.File) {
        // Update File
        const responseUpload = await uploadFileProyek(value.File, 'proyek', event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        imagefilename = responseUpload.data.filename
      }
      if (this.$route.params.id) {
        value.id = this.$route.params.id
        if (value.File) {
          value.img = imagefilename
          delete value.File
        }
        const response = await ubahProyek(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Proyek Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
        }
        this.show = false
      } else {
        if (value.File) {
          value.img = imagefilename
          delete value.File
        }
        // console.log(value)
        const response = await insProyek(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Tambah Proyek Berhasil!')
          setTimeout(() => this.$router.push({ name: 'pages-proyek-ubah', params: { id: response.data.proyek_id } }), 500)
        } else {
          this.makeToast('danger', response.data.data)
        }
        this.show = false
      }
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: 'Proyek',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
  },
}
</script>
