<template>

  <div>
    <form-import
    v-if="$route.params.id"
    @emitFilename="changeFilename"
    />
    <list-filters
    :namaFileOptions="namaFileOptions.options"
    :filename-filter.sync="filenameFilter"
    :statusOptions="statusOptions.options"
    :status-filter.sync="statusFilter"
    :hideNamaFile="hideNamaFile"
    />
    <!-- <list-filters
      :nama-user-filter.sync="namaUserFilter"
      :jenis-kelamin-filter.sync="jenisKelaminFilter"
      :provinsi-filter.sync="provinsiFilter"
      :kota-filter.sync="kotaFilter"
      :proyek-filter.sync="proyekFilter"
      :umur-awal-filter.sync="umurAwalFilter"
      :umur-akhir-filter.sync="umurAkhirFilter"
      :tagAdminOptions="tagAdminOptions.options"
      :tagPublikOptions="tagPublikOptions.options"
      :proyekOptions="proyekOptions.options"
      :provinsiOptions="provinsiOptions.options"
      :kotaOptions="kotaOptions.options"
      :jenisKelaminOptions="jenisKelaminOptions.options"
      :new-filter.sync="newFilter"
      :btn-key.sync="btnKey"
      @emitKota="emitKota"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
            <div
              class="d-flex justify-content-center"
              v-if="loaderShow === true"
              >
                  <b-spinner
                    v-for="(variant, i) in variants"
                    :key="i"
                    :variant="variant"
                    class="mr-1"
                    type="grow"
                  />
              </div>
              <b-button
                variant="primary"
                v-if="$can('log-import', 'manage') && filenameFilter !== null && $route.params.id"
                @click="saveToDatabase"
                :disabled="loaderShow === true"
              >
                <span class="text-nowrap">Simpan ke Database</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(nama)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.nama)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"

              />
            </template>
              {{ data.item.nama }}
            <small class="text-muted">{{ data.item.alamat }}</small>
          </b-media>
        </template>
        <template #cell(status)="data">
          <b-badge pill variant="danger" v-if="data.item.status === 'Failed'">{{data.item.status}}</b-badge>
          <b-badge pill variant="success" v-if="data.item.status === 'Success'">{{data.item.status}}</b-badge>
          <b-badge pill variant="info" v-if="data.item.status === 'New'">{{data.item.status}}</b-badge>
          <b-badge pill variant="warning" v-if="data.item.status === 'Warning'">{{data.item.status}}</b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->
            <!-- <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" :to="{ name: 'asset-viewx', params: { id: data.item.id } }"
              title="Lihat Data"
              alt="Lihat Data"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-button> //-->

            <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="success" :to="{ name: 'log-import-edit', params: { id: data.item.id } }" title="Ubah Data"
              v-if="$can('update', 'log-import')"
            >
              <feather-icon icon="EditIcon" />
              <!-- <span class="align-middle ml-50">Edit</span> //-->
            </b-button>

            <b-button
              class="btn-icon btn btn-relief-primary"
              variant="warning"
              @click="deleteData(data.item.id)" title="Hapus Data" alt="Hapus Data"
            >
              <feather-icon icon="TrashIcon" />
              <!-- <span class="align-middle ml-50">Delete</span> //-->
            </b-button>
          <!-- </b-dropdown> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  deleteKategoriKeterampilan,
  getNamaFile,
  getStatus,
} from '@/api/user'
import {
  importFromLog,
} from '@/api/proyek'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/log-import/storeModule'
import useList from '@/connection-api/log-import/list'
import FormImport from './FormImport.vue'
import listFilters from './ListFilters.vue'

export default {
  components: {
    listFilters,
    FormImport,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BBadge,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      loaderShow: false,
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'primary'],
      hideNama: false,
      namaFileOptions: {
        selected: 'id',
        options: [
          { value: null, label: '---Pilih File---' },
        ],
      },
      statusOptions: {
        selected: 'id',
        options: [
          { value: null, label: '---Pilih Status---' },
        ],
      },
    }
  },
  props: {
    hideNamaFile: {},
    filename: {},
  },
  async mounted() {
    const responsenamaFileOptions = await getNamaFile()
    this.mappingSelectOptionIds(responsenamaFileOptions.data, this.namaFileOptions.options)

    const responsestatusOptions = await getStatus()
    this.mappingSelectOptionIds(responsestatusOptions.data, this.statusOptions.options)
    this.keyFilter = 0
  },
  methods: {
    changeFilename(val) {
      this.filenameFilter = val
      this.keyFilter += 1
    },
    async saveToDatabase() {
      if (window.confirm(`Apakah anda yakin untuk menyimpan data ${this.filenameFilter} ke database?`)) {
        this.loaderShow = true
        const data = {
          filename: this.filenameFilter,
        }
        const response = await importFromLog(data)
        if (response.data.success === true) {
          this.makeToast('success', 'Data berhasil disimpan!')
          this.loaderShow = false
          this.refetchData()
        } else {
          this.makeToast('danger', response.data.message)
          this.loaderShow = false
        }
      }
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: 'Log Import User Proyek',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/log-import/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kategori Keterampilan',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    async deleteData(param) {
      if (window.confirm('Apakah anda yakin?')) {
        const dataPost = { id: param }
        const response = await deleteKategoriKeterampilan(dataPost)
        if (response.data.message === 'error') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'Tidak dapat menghapus data!',
            },
          })
        } else if (response.data.message === 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil dihapus',
            },
          })
          this.$router.go()
        }
      }
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    mappingSelectOptionIds(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.ids,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'log-import'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      keyFilter,
      filenameFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      filenameFilter,
      statusFilter,
      keyFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
